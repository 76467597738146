import { styles } from "./title";
import { Box } from "@mui/material";
import { colors } from "../../utils/const";
import Link from "next/link";
import { useRouter } from "next/router";

export default function Title({ title, h1 }) {
  const router = useRouter();
  const { locale } = router;
  return (
    <Box sx={styles.container} data-aos="fade-up">
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          pb: "20px",
        }}
      >
        <Box sx={styles.title} component={h1 ? h1 : "h3"}>
          {title}
        </Box>

        <Box
          sx={{
            position: "absolute",
            // top:"0px",
            width: "100%",
            height: { xs: "6px", lg: "7px" },
            transform: locale === "en" ? "scaleX(-1)" : "scaleX(1)",
          }}
          component="img"
          alt="image line"
          src="/line.png"
        ></Box>
      </Box>
    </Box>
  );
}
