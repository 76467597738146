export const dot = {
  list: (theme) => ({
    p: 0,
    // mb: 3,
    textAlign: "initial",
    // paddingInlineStart: "1.5em",
    textAlign: "initial",
    "& > li": {
      marginBottom: "1em",
    },
  }),
};
