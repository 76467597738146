export const styles = {
  container: {
    display: "flex",
    justifyContent: { xs: "start", md: "space-between" },
    alignItems: "center",
    flexWrap: { xs: "wrap", md: "nowrap" },
  },
  img: {
    width: { xs: "450px" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: (theme) => ({
    width: { xs: "600px" },
    gap: 2,
  }),
};
