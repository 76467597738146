import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { colors } from "../../utils/const";
import { styles } from "./contact-pay-section";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import Title from "../../components/mini-components/Title.jsx";
import Link from "next/link";
import { dot } from "../../components/dotlistStyle.js";
import Image from "next/image";

export default function IconsSection({}) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  let leftOrRight = locale === "en" ? { mr: 5 } : { ml: 5 };

  return (
    <Box sx={{ bgcolor: "#F8F8F8" }}>
      <Box
        sx={{
          ...styles.container,
          bgcolor: "#fff",
        }}
        className="horizontal-safe-padding vertical-safe-padding"
        id="contact-pay"
      >
        <Box data-aos="fade-up" sx={styles.text}>
          <Box sx={{ display: "flex" }}>
            <Title
              title={t.home_page.bos.title1}
              h1={router.asPath.includes("e-payment") ? "h3" : "h3"}
            />
          </Box>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {t.home_page.bos.subtitle}
          </Typography>
          <Box
            sx={{
              fontWeight: "300",
              fontSize: { xs: "14px", sm: "16px" },
              color: "#404456",
              fontSize: "16px",
              mt: 0.5,
            }}
          >
            {t.home_page.bos.body1}
          </Box>
          <Box>
            <Box
              sx={{ position: "relative", display: "inline-block", zIndex: 1 }}
            >
              {/* Overlay */}
              <Box
                sx={{
                  content: '""',
                  position: "absolute",
                  inset: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.0)",
                  zIndex: -1,
                }}
              />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontSize: "16px",
                  color: colors.orange,
                  fontWeight: "600",
                  my: 2,
                }}
              >
                {t.home_page.bos.WithContactPay_list.title}
              </Typography>
            </Box>

            <Box
              component="ul"
              sx={{
                fontSize: "13px",
                padding: 0,
                ...dot.list,
                display: { sm: "grid" },
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "2px",
                px: 2,
              }}
            >
              {t.home_page.bos.WithContactPay_list.list.map((item, index) => (
                <Box
                  key={index}
                  component="li"
                  sx={{
                    margin: "5px 0",
                    ...leftOrRight,
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#404456",
                    pb: 1,
                  }}
                >
                  {item}
                </Box>
              ))}
            </Box>
          </Box>
          {!router.asPath.includes("e-payment") ? (
            <Link
              href="/e-payment"
              aria-label="read more about e-payment and what you can do with Contact Pay"
            >
              <Button
                variant="outlined"
                sx={{
                  width: { xs: "150px", md: "200px", lg: "300px" },
                  mt: 1,
                  backgroundColor: colors.orange,
                  color: colors.white,
                  border: `none`,
                  "&:hover": {
                    border: `none`,
                    backgroundColor: colors.orange,
                    color: colors.white,
                  },
                }}
              >
                {locale === "en" ? "Read More" : "اقرأ المزيد"}

                {/* hidden descriptive text for screen readers and SEO while keeping "Read More" as the visible text. */}
                <span
                  style={{
                    position: "absolute",
                    clip: "rect(1px, 1px, 1px, 1px)",
                  }}
                >
                  {locale === "en"
                    ? " read more about e-payment and what you can do with Contact Pay"
                    : "اقرأ المزيد عن كونتكت باي"}
                </span>
              </Button>
            </Link>
          ) : (
            ""
          )}
        </Box>
        <Box
          component="img"
          src="/bos.png"
          alt="pos of contact pay"
          loading="eager"
          sx={{
            width: { xs: "200px", md: "450px" },
            margin: { xs: "auto", md: "0" },
            my: { xs: 5, md: "0" },
          }}
        ></Box>
      </Box>
    </Box>
  );
}
