import { colors } from "../../utils/const";

export const styles = {
  container: (theme) => ({
    pt: 1,
    pb: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start" ,
  }),
  title: (theme) => ({
    color: colors.blue,
    fontSize: { xs: "30px", md: "42px" },
    fontWeight: "bold",
  }),
};
